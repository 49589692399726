import './App.css';
import './assets/sass/App.scss';
import "react-toastify/dist/ReactToastify.css";
import { Routing } from './app/routing/Routing';
import { WindowWidthProvider } from './app/contexts/WindowWidthContext';
import { CategoriesProvider } from './app/contexts/CategoriesContext';
import { ProductsProvider } from './app/contexts/ProductContext';
import { PackProvider } from './app/contexts/PackContext';
import { MarquesProvider } from './app/contexts/MarquesContext';
import { ShoppingCartProvider } from './app/contexts/ShoppingCartContext';
import { ShoppingCartDataProvider } from './app/contexts/ShoppingCartDataContext';
import { WishlistProvider } from './app/contexts/WishlistContext';
import { WishlistDataProvider } from './app/contexts/WishlistDataContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { BackToTop } from './app/components/BackToTop/BackToTop';
import { SlidersProvider } from '@contexts/SliderContext';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <WindowWidthProvider>
        <CategoriesProvider>
          <ProductsProvider>
            <PackProvider>
              <MarquesProvider>
                <SlidersProvider>
                  <WishlistProvider>
                    <ShoppingCartProvider>
                      <ShoppingCartDataProvider>
                        <WishlistDataProvider>
                          <HelmetProvider>
                            <ToastContainer limit={5} />
                            <Routing />
                            <BackToTop />
                          </HelmetProvider>
                        </WishlistDataProvider>
                      </ShoppingCartDataProvider>
                    </ShoppingCartProvider>
                  </WishlistProvider>
                </SlidersProvider>
              </MarquesProvider>
            </PackProvider>
          </ProductsProvider>
        </CategoriesProvider>
      </WindowWidthProvider>
    </QueryClientProvider>
  );
}

export default App;